<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as cloneDeep from "lodash.clonedeep";
import * as moment from "moment";
import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import FilterBtn from '../../../components/filter-btn.vue';

import {
  FETCH_PAYMENT_PAGE_DETAILS,
  FETCH_PAYMENT_PAGE_TRANSACTIONS,
  FETCH_CHANNEL_LIST,
  EXPORT_PAYMENT_PAGE_TRANSACTIONS,
  GENERATE_QR_CODE
} from "@/state/actions.type";

import { UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE } from '@/state/mutations.type';
import DownloadBtn from "../../../components/download-btn.vue";
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    Multiselect,
    Spinner,
    FilterBtn,
    DownloadBtn
},
  data() {
    return {
      paymentMethodList:['CARD', 'WALLET', 'ALL'],
      pageDetails: null,
      pageTransactions: [],
      advancedFilterClosed: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      mor_filter: "",
      filter: null,
      filters: {
        to: "",
        from: "",
        pmt_channel:[],
        pmt_method:[],
        status:[],
      },
      merchant_uuids:[],
      sortBy: "created_at",
      sortDesc: true,
      refValueForFilter: "",
      filterOptions: ['Merchant Order Ref', 'Channel Order Ref', 'PortOne Order Ref', 'Billing Name', 'Email', 'Phone Number'],
      currentSelectedFilter: "",
      totalTransactions: "",
      totalRevenue: "",
      pageRef: "",
      availableStockQuantity: 0,
      isStockEnabled: false,
      // isDetailsModalOpen: false,
      // selectedTrasactionData: null,
      choosenPaymentMethods: 0,
      fields: [
        // { key: "show_details", label: 'Options', tooltip: "Options" },
        { key: "created_at", sortable: true, label: "views.payment_pages.table.fields.txn_date", tooltip: "views.payment_pages.table.tooltip.txn_date" },
        { key: "buyer_name", sortable: true, label: "views.payment_pages.table.fields.buyer_name", tooltip: "views.payment_pages.table.tooltip.buyer_name" },
        { key: "merchant_order_ref", sortable: true, label: "views.payment_pages.table.fields.merchant_order_ref", tooltip: "views.payment_pages.table.tooltip.merchant_order_ref" },
        { key: "channel_key", sortable: true, label: "views.payment_pages.table.fields.channel_key", tooltip: "views.payment_pages.table.tooltip.channel_key" },
        { key: "method_name", sortable: true, label: "views.payment_pages.table.fields.method_name", tooltip: "views.payment_pages.table.tooltip.method_name" },
        { key: "order_ref", sortable: true, label: "views.payment_pages.table.fields.order_ref", tooltip: "views.payment_pages.table.tooltip.order_ref" },
        { key: "status", sortable: true, label: "views.payment_pages.table.fields.status_details", tooltip: "views.payment_pages.table.tooltip.status_details" },
        { key: "amount", sortable: true, label: "views.payment_pages.table.fields.amount", tooltip: "views.payment_pages.table.tooltip.amount" },
        { key: "merchant_email_address", sortable: true, label: "views.payment_pages.table.fields.merchant_email_address", thClass: "",tdClass: "" },
        { key: "order_ref", sortable: true, label: "PortOne Order ID", tooltip: "" },
        { key: "status_code", sortable: true, label: "views.payment_pages.table.fields.status_code", tooltip: "views.payment_pages.table.tooltip.status_code" },
        { key: "status_channel_reason", sortable: true, label: "views.payment_pages.table.fields.status_channel_reason", tooltip: "views.payment_pages.table.tooltip.status_channel_reason" },
        { key: "payment_method_used", sortable: true, label: "views.payment_pages.table.fields.payment_method_used", tooltip: "views.payment_pages.table.tooltip.payment_method_used" },
        { key: "channel_name", sortable: true, label: "views.payment_pages.table.fields.channel_name", tooltip: "views.payment_pages.table.tooltip.channel_name" },
      ],
    };
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    dateFormat(date) {
      return moment(date).format("Do MMMM YYYY hh:mm a");
    }
  },

  created() {
    this.pageRef = this.$route.query.pageRef

    this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
      pageRef: this.pageRef,
      submerchantKey: this.$route.query.key
    }).then(() => {
      this.pageDetails = cloneDeep(this.paymentPageDetailStore);
      this.availableStockQuantity = this.pageDetails.content.available_stock_quantity;
      this.isStockEnabled = this.pageDetails.content.is_stock_enabled;
      this.choosenPaymentMethods = Object.keys(this.pageDetails.content.chosen_payment_methods).length;
      this.setTransactionData();
    });

    this.$store.dispatch(`transactions/${FETCH_CHANNEL_LIST}`)
  },

  computed: {
    paymentPageDetailStore() {
      return this.$store.state.paymentPages.pageDetails;
    },

    paymentPageTransactions() {
      return this.$store.state.paymentPages.pageTransactions;
    },

    isFetchingTransactions() {
      return this.$store.state.paymentPages.isFetchingData;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    paymentChannelList() {
      return this.$store.state.transactions.paymentChannelList.filter((value, index, array) => array.indexOf(value) === index);
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    items() {
      return [
        {
          text: this.$t('views.payment_pages.title'),
          active: false,
          href: "/settings/payment-pages"
        },
        {
          text: this.$t('views.payment_pages.details'),
          active: true,
        }
      ];
    },

    title() {
      return this.pageDetails ? this.pageDetails.content.title : '';
    },
  },
  watch: {
    perPage: function () {
      this.setTransactionData();
    },

    currentPage: function () {
      this.setTransactionData();
    },

    selectedTrasactionDataFromStore() {
      this.selectedTrasactionData = cloneDeep(this.selectedTrasactionDataFromStore);
    },

    isStockEnabled(value) {
      if(!value) {
        this.availableStockQuantity = 0;
      }
    },
    'filters.from': function(newFromDate) {
      if (newFromDate !== null && newFromDate !== "2006-01-02T00:00:00.000Z" && newFromDate !== "") {
        const toDate = new Date(newFromDate);
        console.log(toDate)
        toDate.setHours(23, 59, 59); // Set time to the end of the day
        this.filters.to = toDate;
      }
      else{
        this.filters.to = "";
      }
    },
  },

  methods: {
    resetAdvanceFilters() {
      let isFilter = this.filterApplied();
      this.advancedFilterClosed = !this.advancedFilterClosed;

      this.filters = {
        to: "",
        from: "",
        pmt_channel:[],
        pmt_method:[],
        status:[],
      };

      if(isFilter) {
        this.setTransactionData();
      }
    },

    filterTransactionBySelectedRefFilter() {
      if(this.refValueForFilter == "") {
        this.setTransactionData();
      } else {
        this.filters = {
          to: "",
          from: "",
          pmt_channel:[],
          pmt_method:[],
          status:[],
        };

        switch(this.currentSelectedFilter) {
          case 'Merchant Order Ref':
            this.filters.merchant_order_ref = this.refValueForFilter.trim();
            break;
          case 'Channel Order Ref':
            this.filters.channel_order_ref = this.refValueForFilter.trim();
            break;
          case 'PortOne Order Ref':
            this.filters.order_ref = this.refValueForFilter.trim();
            break;
          case 'Billing Name':
            this.filters.buyer_name = this.refValueForFilter.trim();
            break;
          case 'Email':
            this.filters.buyer_email = this.refValueForFilter.trim();
            break;
          case 'Phone Number':
            this.filters.buyer_phone = this.refValueForFilter.trim();
            break;
          default:
            this.refValueForFilter = "";
        }

        this.setTransactionData();
      }
    },

    fetchTransactionByAdvanceFilters() {
      if(this.filters.to == "" || !this.filters.to) {
        this.filters.to = new Date().toISOString()
      }
      if(this.filters.from == "" || !this.filters.from) {
        this.filters.from = new Date('2006-01-02').toISOString()
      }
      this.setTransactionData();
    },
    createPaymentPage() {
      this.$router.push({ path: '/settings/payment-pages/config'})
    },

    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_pages.copy_success'),
        closeOnClick: true,
      });
    },

    onCopyQrSuccess() {
      this.$notify({
        type: "success",
        text: "QR Code Copied Successfully",
        closeOnClick: true,
      });
    },
    downloadQR() {
      const link = document.createElement('a');
      link.href = this.pageDetails.content.qrcode_link;
      link.download = 'QRCode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    editPaymentPage() {
      this.$router.push({ path: '/settings/payment-pages/config', query: { pageRef: this.pageDetails.payment_link_details.payment_page_ref, key: this.isMasterMerchant ? this.pageDetails.content.chaipay_key : '' }})
    },

    setTransactionData() {

      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_TRANSACTIONS}`, {
        payload: {
          page: this.currentPage,
          pagesize: this.perPage,
          filters: this.filters,
          from: moment(this.filters.from || new Date('2006-01-02')).format("YYYY-MM-DD HH:mm:ss"),
          to: moment(this.filters.to || new Date()).format("YYYY-MM-DD HH:mm:ss")
        },
        pageRef: this.pageDetails.payment_link_details.payment_page_ref
      }).then(() => {
        let result = this.paymentPageTransactions;
        var pageTransactionsData = cloneDeep(result.transactions.content) 
        const pageTransactions_content = pageTransactionsData.map((pageTrs) => {
          var charge_params = {}
          var dynamic_column = [];
          if(pageTrs.custom_page_fields){ 
            for (var i in pageTrs.custom_page_fields) {
              var keyname = `${i}`
              var keyvalue = `${pageTrs.custom_page_fields[i] ? pageTrs.custom_page_fields[i] : "-"}`
              charge_params[keyname] = keyvalue
              dynamic_column.push(keyname);
            }            
          }
          var pageTrsstaticData = {
            dynamic_column:dynamic_column,
          };
          var pageTrsstaticDynamicData = {  ...pageTrs, ...pageTrsstaticData, ...charge_params };
          return pageTrsstaticDynamicData
        });
        var dynamic_field = []
        dynamic_field = cloneDeep(this.fields);
          if(pageTransactions_content.length > 0){
            if(pageTransactions_content[0].dynamic_column.length > 0) {
              pageTransactions_content[0].dynamic_column.map(function (item) {
                return dynamic_field.push({ key: item, sortable: true, label: item });
              });
            }
            this.fields = dynamic_field 
          }  
        this.pageTransactions = pageTransactions_content
        this.totalRows = result.transactions.total_elements;
        this.totalRevenue = result.total_amount;
      });
    },

    fetchTransactionOnEmptyFilter() {
      if(this.refValueForFilter == "") {
        this.filters = {
          to: "",
          from: "",
          pmt_channel:[],
          pmt_method:[],
          status:[],
        };
        this.setTransactionData();
      }
    },

    getlangcodefromcurr(currdata) {
      let language;
      if (currdata) {
        if (currdata === "VND") {
          language = "vi";
        } else if (currdata === "THB") {
          language = "th";
        } else {
          language = "en";
        }
      } else {
        language = "vi";
      }
      return language;
    },

    getFormattedNumber(numberValue, currencyValue) {
      let choosenLanguage = this.getlangcodefromcurr(currencyValue);
      let formattedValue = new Intl.NumberFormat(choosenLanguage, {
        style: "currency",
        currency: currencyValue,
        minimumFractionDigits: 2,
      }).format(numberValue);
      return formattedValue;
    },

    filterApplied() {
      return Boolean(this.filters.from || this.filters.to || (this.filters.pmt_channel && this.filters.pmt_channel.length) || (this.filters.pmt_method && this.filters.pmt_method.length) || (this.filters.status && this.filters.status.length));
    },

    downloadExcel() {
      this.$store.dispatch(`paymentPages/${EXPORT_PAYMENT_PAGE_TRANSACTIONS}`, {
        pageData: {
          page: this.currentPage,
          pagesize: this.perPage,
          filters: this.filters,
          from: moment(this.filters.from || new Date('2006-01-02')).format("YYYY-MM-DD HH:mm:ss"),
          to: moment(this.filters.to || new Date()).format("YYYY-MM-DD HH:mm:ss")
        },
        pageRef: this.pageRef
      });
    },
    updateActiveStatus(status) {
      if(this.pageDetails.content.is_active !== status) {
        this.pageDetails.content.is_active = status;
  
        this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, {
          page_ref: this.pageRef,
          is_active: status,
          available_stock_quantity: Number.parseInt(this.availableStockQuantity),
          is_stock_enabled: this.isStockEnabled
        });
      }
    },
    duplicatePage() {
      this.$router.push({ path: '/settings/payment-pages/config', query: { pageRef: this.pageDetails.payment_link_details.payment_page_ref, duplicate: true }})
    },
    updateStock() {
      this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, {
        page_ref: this.pageRef,
        available_stock_quantity: Number.parseInt(this.availableStockQuantity),
        is_stock_enabled: this.isStockEnabled,
      }).then(() => {
        this.$notify({
          type: "success",
          text: this.$t('views.payment_pages.stock_updated_success'),
          closeOnClick: true
        })
        this.pageDetails.content.available_stock_quantity = this.availableStockQuantity;
        this.pageDetails.content.is_stock_enabled = this.isStockEnabled;
      });
    },
    notAfterToday(date) {
      if(this.filters.to) {
        return date > new Date() || date >= new Date(this.filters.to);
      }
      return date > new Date();
    },
    notAfterTodayAndBeforeStartDate(date) {
      if(this.filters.from) {
        return date > new Date() || date <= new Date(this.filters.from);
      }
      return date > new Date();
    },
    generateQR() {
      this.$store.dispatch(`paymentPages/${GENERATE_QR_CODE}`, {
        pageRef: this.pageRef
      }).then((response) => {
        this.pageDetails.content.qrcode_link = response.QRCodeImage;
        this.$notify({
          type: "success",
          text: "QR Code Generated Successfully",
          closeOnClick: true,
        });
      }).catch((error) => {
        // error
        const err = JSON.parse(error.message);
        this.$notify({
          type: "error",
          text: err.message,
          closeOnClick: true,
        });
      });
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="pageDetails">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="row mb-4">
              <div class="col-md-12 d-flex justify-content-end">
                <b-dropdown variant="white" size="sm" class="custom-btn mx-1">
                  <template v-slot:button-content>
                    <img src="@/assets/images/icon-share.svg" class="mb-1 mr-1" alt="share"> {{ $t('views.payment_pages.share') }}
                  </template>
                  <b-dropdown-item-button>
                    <ShareNetwork
                      network="facebook"
                      :url="pageDetails.content.page_link"
                      :title="pageDetails.content.title"
                    >
                      {{ $t('views.payment_pages.share_fb') }}
                    </ShareNetwork>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button>
                    <ShareNetwork
                      network="whatsapp"
                      :url="pageDetails.content.page_link"
                      :title="pageDetails.content.title"
                    >
                      {{ $t('views.payment_pages.share_wa') }}
                    </ShareNetwork>
                  </b-dropdown-item-button>
                </b-dropdown>
                <div>
                  <button class="btn custom-btn mx-1" @click="duplicatePage" v-if="!isMasterMerchant">
                    <i class="mdi mdi-content-duplicate"></i>{{ $t('views.payment_pages.duplicate') }}
                  </button>
                  <button class="btn custom-btn mx-1" @click="generateQR" v-if="!pageDetails.content.qrcode_link">
                    Generate QR
                  </button>
                  <button class="btn custom-btn mx-1" @click="editPaymentPage" v-if="!isMasterMerchant">
                    <img src="@/assets/images/icon-edit.svg" class="mb-1 mr-1" alt="edit">{{ $t("button.edit") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="mx-1 mb-4 row rounded" v-if="pageDetails" style="border: 1px dashed #e3e4e5 !important;font-size: 13px;">
                <div class="col-md-6 p-3">
                  <table class="page-details-1">
                    <colgroup>
                      <col width="30%">
                      <col>
                    </colgroup>
                    <tr>
                      <td>{{ $t('views.payment_pages.page_url') }}:</td>
                      <td>
                        <div class="d-flex justify-content-between">
                          <p class="text-truncate mb-0 my-auto" style="width: 13rem;">
                            <a :href="pageDetails.content.page_link" target="_blank">{{ pageDetails.content.page_link }}</a>
                          </p>
                          <b-btn size="sm" variant="outline-primary" style="" v-clipboard:copy="pageDetails ? pageDetails.content.page_link : ''" v-clipboard:success="onCopySuccess">
                            <i class="mdi mdi-content-copy"></i>{{ $t("common.copy") }}
                          </b-btn>
                        </div>
                        <!-- <img src="@/assets/images/icon-copy.svg" class="mb-1 mr-1" alt="copy"> -->
                      </td>
                      <!-- <td>
                      </td> -->
                    </tr>
                    <tr>
                      <td>{{ $t('views.payment_pages.page_status') }}:</td>
                      <td>
                        <b-btn
                          variant="primary"
                          @click="updateActiveStatus(true)"
                          class="mr-2"
                          style="font-size: 12px; background: #fff;"
                          :style="[ !pageDetails.content.is_active ? `border: 1px solid #DAD9D9; color: #D9D9D9;` : `border: 1px solid #252B3A; color: #252B3A;`]"
                          size="sm"
                        >
                          <div class="checkbox-group">
                            <label class="position-relative mb-0" :class="pageDetails.content.is_active ? `filled` : ``">{{ $t('views.payment_pages.activate') }}</label>
                          </div>
                        </b-btn>
                        <b-btn
                          variant="primary"
                          @click="updateActiveStatus(false)"
                          style="font-size: 12px; background: #fff;"
                          :style="[ pageDetails.content.is_active ? `border: 1px solid #DAD9D9; color: #D9D9D9;` : `border: 1px solid #252B3A; color: #252B3A;`]"
                          size="sm"
                        >
                          <div class="checkbox-group">
                            <label class="position-relative mb-0" :class="!pageDetails.content.is_active ? `filled` : ``">{{ $t('views.payment_pages.deactivate') }}</label>
                          </div>
                        </b-btn>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('views.payment_pages.page_id') }}:</td>
                      <td>{{ pageDetails.payment_link_details.payment_page_ref }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('views.payment_pages.created_on') }}:</td>
                      <td>{{ pageDetails.content.created_at | dateFormat }}</td>
                    </tr>
                    <tr v-if="choosenPaymentMethods > 0">
                      <td>Payment Methods</td>
                      <td>
                        <b-btn class="m-0" size="sm" variant="primary" v-b-modal.payment-methods-view>
                          View
                        </b-btn>
                        
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6 p-3 bg-light">
                  <table class="page-details-2 w-100">
                    <colgroup>
                      <col width="30%">
                      <col width="50%">
                      <col width="20%">
                    </colgroup>
                    <tr>
                      <td>{{ $t('common.amount') }}:</td>
                      <td>{{ getFormattedNumber(pageDetails.content.amount, pageDetails.content.currency) }}</td>
                      <td rowspan="4">
                        <div v-if="pageDetails.content.qrcode_link">
                          <img :src="pageDetails.content.qrcode_link" class="w-100" alt="">
                          <b-btn size="sm" variant="primary" v-clipboard:copy="pageDetails.content.qrcode_link" v-clipboard:success="onCopyQrSuccess" v-b-tooltip.hover.bottom title="copy" class="p-0 px-3" style="font-size: 1rem; cursor: copy;">
                            <i
                              class="mdi mdi-content-copy"
                            ></i>
                          </b-btn>
                          <b-btn size="sm" variant="primary" class="p-0 px-3 float-right" style="font-size: 1rem;" v-b-tooltip.hover.bottom title="download" @click="downloadQR">
                            <i
                              class="mdi mdi-download"
                            ></i>
                          </b-btn>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('views.payment_pages.revenue') }}:</td>
                      <td><span class="badge badge-info">{{ getFormattedNumber(totalRevenue, pageDetails.content.currency) }}</span></td>
                    </tr>
                    <tr>
                      <td>{{ $t('views.payment_pages.unit_sold') }}:</td>
                      <td>
                        <div class="d-flex justify-centent-between">
                          <div class="my-auto">
                            <span class="badge badge-success">{{ pageDetails.content.stock_sold_quantity }}</span>
                            <span v-if="pageDetails.content.is_stock_enabled" class="ml-2">
                              {{ $t('views.payment_pages.of') }} <span class="ml-2 badge badge-primary">{{ Number(pageDetails.content.stock_sold_quantity) + Number(pageDetails.content.available_stock_quantity) }}</span>
                            </span>
                          </div>
                          <b-btn size="sm" variant="link" class="ml-auto mr-2 p-0 font-weight-bold" v-b-modal.update-stock-modal>
                            <i class="mdi mdi-pencil"></i>
                            {{ $t('views.payment_pages.update_stocks') }}
                          </b-btn>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('common.transactions') }}:</td>
                      <td>
                        <span class="badge badge-primary my-auto">{{ totalRows }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <b-modal id="payment-methods-view" v-if="choosenPaymentMethods > 0" no-close-on-backdrop hide-footer title="Payment Methods">
                    <div v-for="(pmtMethod, key, i) in pageDetails.content.chosen_payment_methods" :key="key">
                      <label class="text-primary mb-2">{{key}}</label>
                      <div v-for="(element, index) in pmtMethod" :key="index">
                        <b-img v-bind="{width: 40, height: 40, class: 'mx-2 my-1'}" :src="element.logo" rounded="circle" alt="Circle image"></b-img> <label class="text-primary my-auto">{{element.name}}</label>
                      </div>
                      <hr v-if="i !== (choosenPaymentMethods - 1)">
                    </div>
                  </b-modal>
            </div>
          </div>
        </div>

        <b-modal id="update-stock-modal" :title="$t('views.payment_pages.update_stock')" ok-title="Save" cancel-variant="light" @ok="updateStock" centered>
          <b-form-checkbox
            v-model="isStockEnabled"
            class="mb-3"
          >
            {{ $t('views.payment_pages.limited_stock') }}
          </b-form-checkbox>
          <b-form-input v-model="availableStockQuantity" :disabled="!isStockEnabled" :placeholder="$t('views.payment_pages.available_stock')"></b-form-input>
        </b-modal>

        <div class="row my-4">
          <div class="col-sm-12 col-xl-4 d-flex align-items-baseline">
            <div @click="resetAdvanceFilters">
              <FilterBtn :isActive="filterApplied()" v-b-toggle.transaction-filter-collapse />
            </div>
            <div id="tickets-table_length" class="dataTables_length ml-3">
              <label class="d-flex align-items-baseline">
                <span class="text-nowrap">{{ $t("common.show") }}</span>&nbsp;
                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                <span class="text-nowrap">{{ $t("common.entries") }}</span>
              </label>
            </div>
          </div>

          <div class="col-sm-12 col-md-8 d-flex justify-content-end align-items-center">
            <div class="d-flex">
            <label class="mr-2 text-nowrap"> {{ $t("common.search_by") }}</label>
            <b-form-select v-model="currentSelectedFilter" class="w-50" size="sm" :options="filterOptions">
              <template #first>
                <b-form-select-option value="">-- Please Select Option --</b-form-select-option>
              </template>
            </b-form-select>&nbsp;
            <b-input-group class="w-50">
              <b-form-input type="search" v-model="refValueForFilter" @keyup="fetchTransactionOnEmptyFilter" @keyup.enter="filterTransactionBySelectedRefFilter" class="form-control form-control-sm ml-2" placeholder="Enter ReferenceID"></b-form-input>
              <b-input-group-append>
                <button class="btn btn-primary btn-block btn-sm" @click="filterTransactionBySelectedRefFilter">{{ $t('button.search') }}</button>
              </b-input-group-append>
            </b-input-group>
            </div>
            <div class="down-btn" @click="downloadExcel()">
              <DownloadBtn />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <b-collapse id="transaction-filter-collapse">
              <b-card class="border border-light" title="Transaction Table Filters">
                <div class="row">
                  <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                    <label for="startdate" style="display: block;">{{ $t("common.start_date") }}</label>
                    <date-picker
                      type="datetime"
                      :placeholder="$t('common.start_date')"
                      format="DD-MM-YYYY HH:mm"
                      v-model="filters.from"
                      name="startdate"
                      class="custom-datepicker"
                      prefix-class="xmx"
                      :disabled-date="notAfterToday"
                    ></date-picker>
                  </div>
                  <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                    <label for="enddate" style="display: block;">{{ $t("common.end_date") }}</label>
                    <date-picker
                      type="datetime"
                      :placeholder="$t('common.end_date')"
                      v-model="filters.to"
                      format="DD-MM-YYYY HH:mm"
                      name="enddate"
                      class="custom-datepicker"
                      prefix-class="xmx"
                      :disabled-date="notAfterTodayAndBeforeStartDate"
                    ></date-picker>
                  </div>
                  <div v-if="isMasterMerchant" class="col-sm-12 col-md-4 col-xl-3 mb-3">
                    <label style="display: block;">{{ $t("common.merchants") }}</label>
                    <multiselect
                      v-model="merchant_uuids"
                      :options="merchantListDataFromStore"
                      :placeholder="$t('common.search_merchants')"
                      track-by="email_id"
                      label="email_id"
                      size="sm"
                      :searchable="true"
                      :multiple="true"
                      :taggable="true"
                      class="sm-multiselect"
                    ></multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-xl-3 mt-2">
                    <multiselect
                      v-model="filters.pmt_channel"
                      :options="paymentChannelList"
                      :placeholder="$t('common.search_payment_channels')"
                      class="sm-multiselect"
                      :searchable="true"
                      :multiple="true"
                      :taggable="true"
                    ></multiselect>
                  </div>
                  <div class="col-sm-12 col-md-6 col-xl-3 mt-2">
                    <multiselect
                      v-model="filters.pmt_method"
                      :placeholder="$t('common.search_payment_methods')"
                      class="sm-multiselect"
                      :searchable="true"
                      :options="paymentMethodList"
                      :multiple="true"
                    ></multiselect>
                  </div>
                  <div class="col-sm-12 col-md-6 col-xl-3 mt-2">
                    <multiselect
                      v-model="filters.status"
                      :placeholder="$t('common.search_transaction_status')"
                      class="sm-multiselect"
                      :searchable="true"
                      :options="['Success', 'Initiated','Failed', 'Expired', 'Authorized', 'Error', 'Voided', 'Under Review']"
                      :multiple="true"
                    ></multiselect>
                  </div>
                  <div class="col-sm-12 col-md-6 col-xl-3">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 mt-2">
                        <b-button variant="primary" style="height: 35px;" class="btn btn-block btn-sm" @click="fetchTransactionByAdvanceFilters" >{{ $t('button.apply') }}</b-button>
                      </div>
                      <div class="col-sm-12 col-md-6 mt-2">
                        <b-button variant="light"  style="height: 35px;" size="sm" class="btn btn-block" @click="resetAdvanceFilters" v-b-toggle.transaction-filter-collapse>{{ $t('button.clear') }}</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
        
        <div class="table-responsive text-center table-custom">
          <b-table
            class="table-centered"
            :items="pageTransactions"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :bordered="true"
            :small="true"
            sticky-header="500px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              <span v-b-tooltip.hover.top :title="$t(data.field.tooltip)">
                {{ $t(data.label) }}
              </span>
            </template>

            <template v-slot:cell(created_at)="{ value }">
              {{ value | date }}
            </template>

            <template v-slot:cell(merchant_order_ref)="row">
              <router-link
                v-if="row.item.order_ref"
                :to="'/transactions/payments/payment-details?order_ref=' + row.item.order_ref +'&key='+row.item.merchant_key"
              >
                <span class="text-chai">{{row.value}}</span>
              </router-link>
            </template>

            <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                :class="{
                    'badge-soft-danger': `${row.value}` === 'Failed' || `${row.value}` === 'Expired',
                    'badge-soft-success': `${row.value}` === 'Success',
                    'badge-soft-warning': `${row.value}` === 'Initiated',
                    'badge-orange': row.value === 'Under Review'
                  }"
              >{{ row.value }}</div>
              <div
                v-if="row.item.refund_completed"
                class="badge ml-1 badge-soft-success font-size-12"
              >{{ 'Refund Processed'}}</div>
            </template>

            <template v-slot:cell(amount)="{value, item}">
              {{ getFormattedNumber(value, item.currency) }}
            </template>

            <template v-slot:cell(channel_key)="row">
              <div class="badge font-size-12">
                <img :src="row.item.channel_logo" alt="user-image" class="mr-1" height="16" />
                <span class="align-middle">{{ row.value }}</span>
              </div>
            </template>
          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-right"
              style="padding-bottom: 20px;"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :current-page="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
            <div class="clear-both"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.xmx-table-date .today {
  color: #2a90e9;
}
</style>
<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eff2f7;
  padding-top: 12px;
  padding-bottom: 12px;    
  font-size: 13px;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
::v-deep .table-custom .table.b-table > thead > tr > .table-b-table-default{
  font-size:12px;
  border-right: 1px solid #D9D9D9;
  background-color: #F5F5F5;
}
::v-deep .main-content {
    overflow: visible;
}
::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
}
.page-details-1 td:first-child{
  height: 33px;
  color:#B3AFAF;
}

.page-details-2 td:first-child{
  height: 33px;
}
.badge {
  font-size: 95% !important;
}
.custom-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  opacity: 1;
}
::v-deep .multiselect__placeholder {
  white-space: nowrap !important;
}
.badge-orange {
  color: #FA8234;
  background-color: #FA823414;
}
.down-btn button{
  height: 31px;
  position: relative;
  margin-left: 10px;
}
::v-deep .btn{
  border-radius: 4px;
}
</style>